<template src='./QuickResponseCodeLogin.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AuthService from '@/services/AuthService';
import AxiosService from '@/services/Axios/AxiosService';
import AssetService from '@/services/AssetService';
import QuickResponseCodeRequest from '@/requests/QuickResponseCodeRequest';
import QuickResponseCodeResource from '@/resources/QuickResponseCodeResource';
import QuickResponseCodeService from '@/services/QuickResponseCodeService';
import UserGameParamsService from '@/services/UserGameParamsService';

@Component({})
export default class QuickResponseCodeLogin extends Vue {
    private authService: AuthService;
    private laravelErrorsService: LaravelErrorsService;
    private axiosService: AxiosService;
    private assetService: AssetService;
    private quickResponseCodeService: QuickResponseCodeService;
    private quickResponseCodeRequest: QuickResponseCodeRequest;
    private quickResponseCodeResource: QuickResponseCodeResource;
    private userGameParamsService: UserGameParamsService;

    constructor(options: any) {
        super(options);
        this.laravelErrorsService = new LaravelErrorsService();
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.assetService = new AssetService();
        this.quickResponseCodeService = new QuickResponseCodeService();
        this.quickResponseCodeRequest = new QuickResponseCodeRequest(this);
        this.quickResponseCodeResource = new QuickResponseCodeResource(this);
        this.userGameParamsService = new UserGameParamsService(this);
    }

    public mounted(): void {
        this.collectAll();
    }

    private async collectAll(): Promise<void> {
        await axios.all([
            this.quickResponseCodeRequest.store(null, `login`, {
                'signature': this.$route.params.signature,
            }),
        ]).then(
            axios.spread((...responses: any) => {
                this.quickResponseCodeResource.store(() => {
                    this.laravelErrorsService.destroyErrors();
                    const response: any = responses[0]?.data;

                    if (response.user && response.type === 'authenticated-via-session') {
                        this.$store.dispatch('storeUser', response.user);
                        this.userGameParamsService
                            .setParams(response.user.user_game_params)
                            .toIndividualUserGame()
                            .toTeamUserGame()
                            .toGames();
                    }
                });
            }),
        ).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get user() {
        return this.$store.getters.user;
    }
}
</script>
