import { Store } from 'vuex';

export default class ModalService {
    public $store: Store<unknown>;

    public $root: any;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$root = parent.$root;
    }

    public observe(hiddenCallback: any): void {
        this.$root.$on('bv::modal::show', () => {
            this.$store.dispatch('storeIsAnswering', true);
        });

        this.$root.$on('bv::modal::shown', () => {
            this.$store.dispatch('storeIsAnswering', true);
        });

        this.$root.$on('bv::modal::hidden', () => {
            this.$store.dispatch('storeIsAnswering', false);
            this.$store.dispatch('storeMapUserLocation', {});
            hiddenCallback();
        });
    }

    public show(locationId: number | string): void {
        this.$root.$emit('bv::show::modal', `modal-${locationId}`, '#btnShow');
    }

    public hide(locationId: number | string): void {
        this.$root.$emit('bv::hide::modal', `modal-${locationId}`, '#btnShow');
    }
}
