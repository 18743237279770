import { RouteConfig } from 'vue-router';
import Home from '@/views/pages/home/Home.vue';
import Games from '@/views/pages/games/Games.vue';
import NotFound from '@/views/pages/not-found/NotFound.vue';
import ResetPassword from '@/views/pages/auth/reset/ResetPassword.vue';
import Login from '@/views/pages/auth/login/Login.vue';
import Register from '@/views/pages/auth/register/Register.vue';
import ForgotPassword from '@/views/pages/auth/forgot/ForgotPassword.vue';
import Game from '@/views/pages/game/Game.vue';
import Cart from '@/views/pages/cart/Cart.vue';
import OrderCancel from '@/views/pages/order/cancel/OrderCancel.vue';
import OrderFailed from '@/views/pages/order/failed/OrderFailed.vue';
import Faq from '@/views/pages/faq/Faq.vue';
import UserGameUnPaid from '@/views/pages/user-game/state/unpaid/UserGameUnPaid.vue';
import UserGameInvalid from '@/views/pages/user-game/state/invalid/UserGameInvalid.vue';
import UserGameExpired from '@/views/pages/user-game/state/expired/UserGameExpired.vue';
import TeamCreate from '@/views/pages/team/TeamCreate.vue';
import TeamEndgame from '@/views/pages/endgame/team/TeamEndgame.vue';
import TeamUserGame from '@/views/pages/user-game/team/TeamUserGame.vue';
import IndividualUserGame from '@/views/pages/user-game/individual/IndividualUserGame.vue';
import IndividualEndgame from '@/views/pages/endgame/individual/IndividualEndgame.vue';
import GameUnpublished from '@/views/pages/game/unpublished/GameUnpublished.vue';
import QuickResponseCodeCerberus from '@/views/pages/qr-auth/cerberus/QuickResponseCodeCerberus.vue';
import QuickResponseCodeLogin from '@/views/pages/qr-auth/login/QuickResponseCodeLogin.vue';
import QuickResponseCodeRegister from '@/views/pages/qr-auth/register/QuickResponseCodeRegister.vue';
import QuickResponseCodeForgotUserName from '@/views/pages/qr-auth/forgot/QuickResponseCodeForgotUserName.vue';
import UserGameExceedLimit from '@/views/pages/user-game/state/exceed-limit/UserGameExceedLimit.vue';
import UserGameInvalidType from '@/views/pages/user-game/state/invalid-game-type/UserGameInvalidType.vue';

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: 'index.html',
                component: Home,
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true,
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            guest: true,
        },
    },
    {
        path: '/forgot/password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            guest: true,
        },
    },
    {
        path: '/reset/password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            guest: true,
        },
    },
    {
        path: '/qr/cerberus/:signature',
        name: 'QuickResponseCodeCerberus',
        component: QuickResponseCodeCerberus,
        meta: {
            guest: true,
        },
    },
    {
        path: '/qr/login/:signature',
        name: 'QuickResponseCodeLogin',
        component: QuickResponseCodeLogin,
        meta: {
            guest: true,
        },
    },
    {
        path: '/qr/register/:signature',
        name: 'QuickResponseCodeRegister',
        component: QuickResponseCodeRegister,
        meta: {
            guest: true,
        },
    },
    {
        path: '/qr/forgot/username/:signature',
        name: 'QuickResponseCodeForgotUserName',
        component: QuickResponseCodeForgotUserName,
        meta: {
            guest: true,
        },
    },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq,
    },
    {
        path: '/games',
        name: 'Games',
        component: Games,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/games/:id',
        name: 'GamesShow',
        component: Game,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/game/unpublished',
        name: 'GameUnpublished',
        component: GameUnpublished,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/:gameId/unpaid',
        name: 'UserGameUnPaid',
        component: UserGameUnPaid,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/:gameId/:userGameId/invalid',
        name: 'UserGameInvalid',
        component: UserGameInvalid,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/:gameId/:userGameId/expired',
        name: 'UserGameExpired',
        component: UserGameExpired,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/:gameId/:userGameId/:teamKey/exceed/limit',
        name: 'UserGameExceedLimit',
        component: UserGameExceedLimit,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/invalid/type',
        name: 'UserGameInvalidType',
        component: UserGameInvalidType,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/:gameId/team/:userGameId',
        name: 'TeamCreate',
        component: TeamCreate,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/:gameId/:userGameId',
        name: 'IndividualUserGame',
        component: IndividualUserGame,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/endgame/:gameId/:userGameId',
        name: 'IndividualEndgame',
        component: IndividualEndgame,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/user/game/team/:gameId/:userGameId/:key',
        name: 'TeamUserGame',
        component: TeamUserGame,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/endgame/team/:gameId/:userGameId/:key',
        name: 'TeamEndgame',
        component: TeamEndgame,
        meta: {
            auth: true,
            verified: true,
        },
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
        meta: {
            auth: true,
        },
    },
    {
        path: '/order/:id/cancel',
        name: 'OrderCancel',
        component: OrderCancel,
        meta: {
            auth: true,
        },
    },
    {
        path: '/order/:id/failed',
        name: 'OrderFailed',
        component: OrderFailed,
        meta: {
            auth: true,
        },
    },
    {
        path: '*',
        component: NotFound,
    },
];

export default routes;
