<template src='./NotFound.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NotFound extends Vue {
    get user() {
        return this.$store.getters.user;
    }

    public created() {
        this.$store.dispatch('storeUser');
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }
}
</script>
<style lang='scss' scoped src='./NotFound.scss'></style>
