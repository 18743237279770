import { render, staticRenderFns } from "./IndividualUserGame.html?vue&type=template&id=bf047ab8&scoped=true&external"
import script from "./IndividualUserGame.vue?vue&type=script&lang=ts"
export * from "./IndividualUserGame.vue?vue&type=script&lang=ts"
import style0 from "./IndividualUserGame.scss?vue&type=style&index=0&id=bf047ab8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf047ab8",
  null
  
)

export default component.exports