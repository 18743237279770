<template src='./faq.html'></template>
<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import AuthService from '@/services/AuthService';
import AxiosService from '@/services/Axios/AxiosService';
import LaravelErrorsService from '@/services/LaravelErrorsService';

@Component({})
export default class Faq extends Vue {
    private authService: AuthService;

    private axiosService: AxiosService;

    private laravelErrorsService: LaravelErrorsService;

    private isBusy: boolean = true;

    private faqs: Iterable<Object> = [];

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    get user(): Object | any {
        return this.$store.getters.user;
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    public created() {
        this.faqsIndex();
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    @Watch('currentLocale')
    onLocaleChange(): void {
        this.faqsIndex();
    }

    private faqsIndex(): void {
        axios.get(`${process.env.API_URL}/faqs`,
            this.axiosService.config(),
        ).then((response: any) => {
            this.faqs = response.data;
        }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        }).finally(() => {
            this.isBusy = false;
        });
    }
}
</script>
<style lang='scss' scoped src='./faq.scss'></style>
