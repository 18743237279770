import { Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import routes from '@/router/routes';

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default {
    router,
};
