import { render, staticRenderFns } from "./IndividualEndgame.html?vue&type=template&id=44dc0d20&scoped=true&external"
import script from "./IndividualEndgame.vue?vue&type=script&lang=ts"
export * from "./IndividualEndgame.vue?vue&type=script&lang=ts"
import style0 from "./IndividualEndgame.scss?vue&type=style&index=0&id=44dc0d20&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44dc0d20",
  null
  
)

export default component.exports