<template src='./QuickResponseCodeCerberus.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AuthService from '@/services/AuthService';
import AxiosService from '@/services/Axios/AxiosService';
import AssetService from '@/services/AssetService';
import QuickResponseCodeRequest from '@/requests/QuickResponseCodeRequest';
import QuickResponseCodeResource from '@/resources/QuickResponseCodeResource';
import axios from 'axios';
import QuickResponseCodeService from '@/services/QuickResponseCodeService';

@Component({})
export default class QuickResponseCodeCerberus extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private axiosService: AxiosService;

    private assetService: AssetService;

    private quickResponseCodeService: QuickResponseCodeService;

    private quickResponseCodeRequest: QuickResponseCodeRequest;

    private quickResponseCodeResource: QuickResponseCodeResource;

    constructor(options: any) {
        super(options);
        this.laravelErrorsService = new LaravelErrorsService();
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.assetService = new AssetService();
        this.quickResponseCodeService = new QuickResponseCodeService();
        this.quickResponseCodeRequest = new QuickResponseCodeRequest(this);
        this.quickResponseCodeResource = new QuickResponseCodeResource(this);
    }

    public mounted(): void {
        this.collectAll();
    }

    private async collectAll(): Promise<void> {
        await axios.all([
            this.quickResponseCodeRequest.store(null, `cerberus`, {
                'signature': this.$route.params.signature,
            }),
        ]).then(
            axios.spread((...responses: any) => {
                this.quickResponseCodeResource.store(() => {
                        this.laravelErrorsService.destroyErrors();
                        const response: any = responses[0].data;

                        if (response.type) {
                            this.$router.push({
                                name: this.quickResponseCodeService.getRouteName(response.type),
                                params: {
                                    signature: this.$route.params.signature,
                                },
                            });
                        }
                    },
                );
            }),
        ).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get user() {
        return this.$store.getters.user;
    }
}
</script>
