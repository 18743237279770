import axios from 'axios';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import { Store } from 'vuex';
import PromoRequest from '@/requests/PromoRequest';
import PromoResource from '@/resources/PromoResource';
import AuthService from '@/services/AuthService';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18';

export default class PromoService {
    public $store: Store<unknown>;

    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private promoRequest: PromoRequest;

    private promoResource: PromoResource;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.authService = new AuthService(parent.$router, parent.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.promoRequest = new PromoRequest(parent);
        this.promoResource = new PromoResource(parent);
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public update(): void {
        axios.all([
            this.promoRequest.update(this.map.game.user_game.promo.id, null),
        ]).then(
            axios.spread((...responses) => {
                this.promoResource.update(responses[0].data);
            }),
        )
            .catch((errors: any) => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }

    private $t(key: string): TranslateResult {
        return i18n.t(key, this.currentLocale);
    }
}
