<template src='./busy.html'></template>
<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Busy extends Vue {
    @Prop({ default: true })
    private isBusy!: boolean | null;
}
</script>
<style lang='scss' scoped src='./busy.scss'></style>
