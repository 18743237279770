<template src='./home.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AssetService from '@/services/AssetService';

@Component({})
export default class Home extends Vue {
    private assetService: AssetService;

    private isBusy: boolean = true;

    constructor(options: any) {
        super(options);
        this.assetService = new AssetService();
    }

    get user() {
        return this.$store.getters.user;
    }

    public created() {
        this.$store.dispatch('storeUser');
    }

    public mounted() {
        this.isBusy = false;
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private goTo(): void {
        if (this.user !== null) {
            this.$router.push({
                name: 'Games',
            });
        }

        if (this.user === null) {
            this.$router.push({
                name: 'Login',
            });
        }
    }
}
</script>
<style lang='scss' scoped src='./home.scss'></style>

