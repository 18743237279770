import { Store } from 'vuex';
import ValidateResponseService from '@/services/ValidateResponseService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import VueRouter from 'vue-router';

export default class UserGameExpiredResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private validateResponseService: ValidateResponseService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public index(response: any, endgame: boolean = false): void {
        const expired: boolean = response.user_game_expired;
        this.laravelErrorsService.destroyErrors();
        this.$store.dispatch('storeIsUserGameExpired', expired);

        if (expired && !endgame) {
            this.$store.dispatch('storeIsBusy', true);
            this.validateResponseService.redirectEndgameType();
        }

        if (!expired && endgame) {
            this.$store.dispatch('storeIsBusy', true);
            this.validateResponseService.redirectGameType();
        }
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }

    get game(): any {
        return this.$store.getters.game;
    }
}
