import Pusher, { Channel } from 'pusher-js';

class VuePusher {
    public pusher: Pusher;
    public channels: string[];

    constructor(apiKey: string, options: any) {
        this.pusher = new Pusher(apiKey, options);
        this.channels = [];
    }

    public subscribe(channelName: string, callback: any): void {
        const channel: Channel = this.pusher.subscribe(channelName);

        if (!this.channels.includes(channelName)) {
            this.channels.push(channelName);
        }

        callback(channel);
    }

    public unsubscribe(channelName: string): void {
        this.pusher.unsubscribe(channelName);
        this.channels = this.channels.filter(name => name !== channelName);
    }

    public getChannels(): string[] {
        return this.channels;
    }
}

export default {
    install(Vue: any, options: any): any {
        const pusher = new VuePusher(options.apiKey, options);

        Vue.prototype.pusher = pusher;
        Vue.prototype.$pusher = pusher.pusher;
    },
};
