<template src='./forgot-password.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import axios from 'axios';
import AxiosService from '@/services/Axios/AxiosService';

@Component({})
export default class ForgotPassword extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private axiosService: AxiosService;

    private form: Object = {
        email: '',
    };

    private response: any = {};

    constructor(options: any) {
        super(options);
        this.laravelErrorsService = new LaravelErrorsService();
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
    }

    get invalidEmail(): boolean {
        return typeof this.laravelErrorsService.errors.email === 'undefined';
    }

    get user() {
        return this.$store.getters.user;
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private storeForgotPassword(event: any): void {
        event.preventDefault();

        axios.post(`${process.env.API_URL}/forgot-password`,
            this.form,
            this.axiosService.config())
            .then((response: any) => {
                this.laravelErrorsService.destroyErrors();
                this.response = response.data;
            })
            .catch((errors: any) => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }
}
</script>
<style lang='scss' scoped src='./forgot-password.scss'></style>
