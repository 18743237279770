import { Store } from 'vuex';
import VueRouter from 'vue-router';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import ValidateResponseService from '@/services/ValidateResponseService';
import QuickResponseCodeService from '@/services/QuickResponseCodeService';
import { Route } from 'vue-router/types/router';

export default class QuickResponseCodeResource {
    public $store: Store<unknown>;

    public $route: Route;

    public $router: VueRouter;

    private validateResponseService: ValidateResponseService;

    private laravelErrorsService: LaravelErrorsService;

    private quickResponseCodeService: QuickResponseCodeService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$route = parent.$route;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
        this.quickResponseCodeService = new QuickResponseCodeService();
    }

    public store(callback: any): void {
        callback();
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }
}
