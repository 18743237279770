<template src='./game.html'></template>
<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AxiosService from '@/services/Axios/AxiosService';

@Component({})
export default class Game extends Vue {
    private authService: AuthService;

    private axiosService: AxiosService;

    private laravelErrorsService: LaravelErrorsService;

    private types: Iterable<Object> | any = [];

    private orders: Iterable<Object> | any = [];

    private isBusy: boolean = false;

    private selected: any = {
        contestants: 1,
        type: {
            game_price: {},
        },
        price: {
            total: 0.00,
            cents: 0,
        },
    };

    private discount: any = {
        coupon: null,
        percentage: null,
        amount: 0,
    };

    private store: Object | any = {
        userGame: {},
        order: {},
    };

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    get user() {
        return this.$store.getters.user;
    }

    public created(): void {
        this.$store.dispatch('storeUser');
        this.showGameTypes();
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    protected setGameType(gameType: any): void {
        this.selected.type = gameType;
    }

    @Watch('selected.type')
    protected onSelectedType(val: any, oldVal: any): void {
        if (typeof this.selected.type !== 'undefined') {
            this.calculatePrice();
        }
    }

    @Watch('selected.contestants')
    protected onSelectedContestant(val: any, oldVal: any): void {
        if (typeof val !== 'undefined' && this.types.length === 2) {
            val >= 2
                ? this.setGameType(this.types[1])
                : this.setGameType(this.types[0]);
        }

        if (typeof this.selected.type !== 'undefined') {
            let typeGamePrice: Object | any = this.selected.type.game_price;
            if (typeof typeGamePrice.price !== 'undefined') {
                this.calculatePrice();
            }
        }
    }

    protected calculatePrice(): void {
        let typeGamePrice: Object | any = this.selected.type.game_price;
        let total: any = typeGamePrice.price * parseInt(this.selected.contestants);

        this.storeTotal(total);
        this.storeTotalInCents(total);
    }

    protected storeTotal(total: string): void {
        this.selected.price.total = parseFloat(total).toFixed(2);
    }

    protected storeTotalInCents(total: number): void {
        this.selected.price.cents = total * 100;
    }

    private showGameTypes(): void {
        axios.get(`${process.env.API_URL}/gameTypes/${this.$route.params.id}`,
            this.axiosService.config(),
        ).then((response: any) => {
            this.isBusy = false;
            this.types = response.data;
            this.setGameType(this.types[0]); // sets default type
        }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private storeUserGameWithOrder(): void {
        this.isBusy = true;
        axios.post(`${process.env.API_URL}/userGames`,
            {
                contestants: this.selected.contestants,
                game_id: this.$route.params.id,
                game_type_id: this.selected.type.id,
                coupon: this.discount.coupon,
            },
            this.axiosService.config(),
        ).then((response: any) => {
            this.laravelErrorsService.destroyErrors();
            this.store.userGame = response.data;
            this.store.order = response.data.order;
            this.$store.dispatch('storeCart', response.data);
            this.$router.push({
                name: 'Cart',
            });
        }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        }).finally(() => {
            this.isBusy = false;
        });
    }

    /**
     *
     * @param userGame
     * @param type
     * @private
     */
    private goToUserGame(userGame: any, type: any) {
        const orderStatus: string = userGame?.order?.status;
        const gameId: string = this.$route.params.id;

        if (orderStatus === 'failed' ||
            orderStatus === 'processing' ||
            orderStatus === 'canceled') {
            this.$router.push({
                name: 'UserGameUnPaid',
                params: {
                    id: userGame.id,
                },
            });
        }

        if (orderStatus === 'success' && userGame?.team === null) {
            this.$router.push({
                name: 'TeamCreate',
                params: {
                    gameId: gameId,
                    userGameId: userGame.id,
                },
            });
        }

        if (orderStatus === 'success' && userGame?.team !== null) {
            // Individual
            if (type?.id === 1) {
                this.$router.push({
                    name: 'IndividualUserGame',
                    params: {
                        gameId: gameId,
                        userGameId: userGame.id,
                    },
                });
            }

            // Team
            if (type?.id === 2) {
                this.$router.push({
                    name: 'TeamUserGame',
                    params: {
                        gameId: gameId,
                        userGameId: userGame.id,
                        key: userGame.team.key,
                    },
                });
            }
        }
    }
}
</script>
<style lang='scss' scoped src='./game.scss'></style>
