import { Store } from 'vuex';
import ValidateResponseService from '@/services/ValidateResponseService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import VueRouter from 'vue-router';
import _ from 'underscore';
// @ts-ignore
import ucwords from 'ucwords/src/ucwords';

export default class QuizResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private validateResponseService: ValidateResponseService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }

    get game(): any {
        return this.$store.getters.game;
    }

    public index(response: any): void {
        this.laravelErrorsService.destroyErrors();
        // this.$store.dispatch('storeUserAnswer', response);
    }

    public show(response: any): void {
        const responseQuizzes: any = response.data;
        this.laravelErrorsService.destroyErrors();
        this.validateResponseService.isEmptyResponse(responseQuizzes);
        this.$store.dispatch('storeIsBusy', true);
        this.$store.dispatch('storeEndGameQuizzes', responseQuizzes);
        this.$store.dispatch('storeEndGameStatistics', [response.quizzes_stats, response.locations_stats]);
        this.$store.dispatch('storeEndGameUserAnswers', this.answers(response));
    }

    public store(response: any): void {
        console.log(response);
    }

    public destroy(response: any): void {
        console.log(response);
    }

    private answers(response: any): any {
        const quizzes: any = response.data.map((quiz: any) => {
            const answers: any = quiz.answers;

            return answers.length >= 1 ? {
                'Jautājums': quiz.description,
                'Mana atbilde': quiz.user_answer !== null && quiz.user_answer.text !== null
                    ? ucwords(quiz.user_answer.text)
                    : this.answerTypeQuiz(quiz),
                'Pareizā atbilde': answers.length >= 2
                    ? answers[0].correct
                    : answers.length === 1 && answers[0] !== null
                        ? answers[0].correct
                        : '-',
            } : 'undefined';
        }).filter((item: any) => item !== 'undefined');

        return _.union(quizzes, [response.quizzes_stats], [response.locations_stats]);
    }

    private answerTypeQuiz(quiz: any): string | null | number {
        if (quiz.user_answer !== null && quiz.user_answer.answer_id !== null && quiz.user_answer.answer.correct !== null) {
            return quiz.user_answer.answer.correct;
        }

        if (quiz.user_answer !== null && quiz.user_answer.answer_id !== null && quiz.user_answer.answer.maybe !== null) {
            return quiz.user_answer.answer.maybe;
        }

        if (quiz.user_answer !== null && quiz.user_answer.answer_id !== null && quiz.user_answer.answer.wrong !== null) {
            return quiz.user_answer.answer.wrong;
        }

        return '-';
    }
}
