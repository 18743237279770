<template src='./TeamCreate.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import axios from 'axios';
import AxiosService from '@/services/Axios/AxiosService';
import AssetService from '@/services/AssetService';

@Component({})
export default class TeamCreate extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private axiosService: AxiosService;

    private assetService: AssetService;

    private team: Object | any = {
        name: null,
        key: null,
        tos: true,
    };

    private game: Object | any = {
        id: null,
        user_game: {
            id: null,
        },
    };

    private data: Object | any = {};

    private isBusy: boolean = true;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.axiosService = new AxiosService(this.user);
        this.assetService = new AssetService();
        this.game.id = parseInt(this.$route.params.gameId);
        this.game.user_game.id = parseInt(this.$route.params.userGameId);
    }

    get invalidUserGameId(): boolean {
        return typeof this.laravelErrorsService.errors.user_game_id !== 'undefined';
    }

    get invalidName(): boolean {
        return typeof this.laravelErrorsService.errors.name === 'undefined';
    }
    get invalidKey(): boolean {
        return typeof this.laravelErrorsService.errors.key === 'undefined';
    }

    get invalidTos(): boolean {
        return typeof this.laravelErrorsService.errors.tos === 'undefined';
    }

    get user() {
        return this.$store.getters.user;
    }

    public created(): void {
        this.$store.dispatch('storeUser');
        this.$store.dispatch('destroyCart', {});
        this.showUserGame();
        this.generateTeamKey();
    }

    private generateTeamKey(): void {
        axios.get(`${process.env.API_URL}/teams/generate/key`,
            this.axiosService.config(),
        ).then(response => {
            this.laravelErrorsService.destroyErrors();
            this.team = response.data;
        }).catch(errors => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private showUserGame(): void {
        axios.get(`${process.env.API_URL}/userGames/${this.game.id}/${this.game.user_game.id}`,
            this.axiosService.config(),
        ).then((response: any) => {
            this.laravelErrorsService.destroyErrors();
            const responseData: any = response.data[0];
            this.data = responseData;
            const gameType: any = responseData.game_type;

            // order not exists, userGame not exists, team not exists
            if (response.data.length === 0) {
                this.$router.push({
                    name: 'UserGameInvalid',
                    params: {
                        id: this.game.id,
                        userGameId: this.game.user_game.id,
                    },
                });
            }

            // order: not exists
            if (responseData.order === null) {
                this.$router.push({
                    name: 'UserGameUnPaid',
                    params: {
                        id: this.game.user_game.id,
                    },
                });
            }

            // user: invalid user accessing game for the type 1
            if (responseData.user_id !== this.user.id && responseData.game_type.type === 1) {
                this.$router.push({
                    name: 'UserGameInvalid',
                    params: {
                        id: this.game.id,
                        userGameId: this.game.user_game.id,
                    },
                });
            }

            // order: success exists, userGame exists, team exists
            if (responseData.order.status === 'success'
                && responseData.order.user_game
                && responseData.order.user_game.team) {
                // Individual
                if (gameType.type === 1) {
                    this.$router.push({
                        name: 'IndividualEndgame',
                        params: {
                            gameId: this.game.id,
                            userGameId: responseData.order.user_game.id,
                        },
                    });
                }

                // Team
                if (gameType.type === 2) {
                    this.$router.push({
                        name: 'TeamUserGame',
                        params: {
                            gameId: this.game.id,
                            userGameId: responseData.order.user_game.id,
                            key: responseData.order.user_game.team.key,
                        },
                    });
                }
            }

            // order exists, userGame exists, team exists
            if (responseData.order.status !== 'success'
                && responseData.order.user_game
                && responseData.order.user_game.team) {
                this.$router.push({
                    name: 'UserGameInvalid',
                    params: {
                        id: this.game.id,
                        userGameId: this.game.user_game.id,
                    },
                });
            }

            // order exists, userGame exists, team not exists
            if (responseData.order.status !== 'success'
                && responseData.order.user_game
                && responseData.order.user_game.team === null) {
                this.$router.push({
                    name: 'UserGameInvalid',
                    params: {
                        id: this.game.id,
                        userGameId: this.game.user_game.id,
                    },
                });
            }

            // order exists, userGame not exists, team not exists
            if (responseData.order.status !== 'success'
                && responseData.order.user_game === null
                && responseData.order.user_game.team === null) {
                this.$router.push({
                    name: 'UserGameInvalid',
                    params: {
                        id: this.game.id,
                        userGameId: this.game.user_game.id,
                    },
                });
            }

            // order not exists, userGame not exists, team not exists
            if (responseData.order === null
                && responseData.order.user_game === null
                && responseData.order.user_game.team === null) {
                this.$router.push({
                    name: 'UserGameInvalid',
                    params: {
                        id: this.game.id,
                        userGameId: this.game.user_game.id,
                    },
                });
            }

            this.isBusy = false;
        }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private storeTeam(event: any): void {
        event.preventDefault();

        axios.post(`${process.env.API_URL}/teams`, {
                ...this.team,
                user_game_id: this.game.user_game.id,
                tos: true,
            },
            this.axiosService.config(),
        )
            .then(response => {
                this.laravelErrorsService.destroyErrors();
                this.team = response.data;
                const gameType: any = this.data.game_type;
                // Individual
                if (gameType.type === 1) {
                    this.$router.push({
                        name: 'IndividualUserGame',
                        params: {
                            gameId: this.game.id,
                            userGameId: this.data.id,
                        },
                    });
                }

                // Team
                if (gameType.type === 2) {
                    this.$router.push({
                        name: 'TeamUserGame',
                        params: {
                            gameId: this.game.id,
                            userGameId: this.data.id,
                            key: this.team.key,
                        },
                    });
                }
            })
            .catch(errors => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }

    private onReset(event: any): void {
        event.preventDefault();
        this.team = {
            name: null,
            key: null,
            tos: true,
        };
        this.generateTeamKey();
        this.laravelErrorsService.destroyErrors();
    }

    private hideModal(locationId: number | string): void {
        this.$root.$emit('bv::hide::modal', `modal-${locationId}`, '#btnShow');
    }
}
</script>
<style lang='scss' scoped src='./TeamCreate.scss'></style>
