import { Store } from 'vuex';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import LocationResource from '@/resources/LocationResource';
import LocationRequest from '@/requests/LocationRequest';
import VueRouter from 'vue-router';
import _ from 'underscore';
import i18n from '@/i18';
import { TranslateResult } from 'vue-i18n';

export default class LocationService {
    public $store!: Store<unknown> | any;

    public $router: VueRouter;

    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private locationRequest: LocationRequest;

    private locationResource: LocationResource;

    private positionOptions: Object | any = {
        enableHighAccuracy: true,
        maximumAge: 1,
        timeout: 1,
    };

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.locationRequest = new LocationRequest(parent);
        this.locationResource = new LocationResource(parent);
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public watchUserLocation(): void {
        let geolocation: Geolocation = navigator.geolocation;

        if (geolocation) {
            this.$store.dispatch('storeIsEnabledGeographicLocation', true);
            geolocation.watchPosition((geolocationPosition: GeolocationPosition) => this.showLocation(geolocationPosition),
                (geolocationPositionError: GeolocationPositionError) => this.handlerErrors(geolocationPositionError),
                this.positionOptions,
            );
        }
    }

    public showLocation(position: any): void {
        const coordinates: any = position.coords;
        this.$store.dispatch('storeMapUserCoordinates', `${coordinates.latitude}, ${coordinates.longitude}`);
        this.$store.dispatch('storeMapOnMapCoordinates', {
            lat: parseFloat(coordinates.latitude),
            lng: parseFloat(coordinates.longitude),
        });

        setTimeout(() => {
            if (!this.map.isBusy || !this.map.isAnswering || !this.map.isThankYou) {
                this.watchNearestLocation();
            }
        }, 500);
    }

    public watchNearestLocation(): void {
        this.locationRequest.store(null, 'nearest', {
            gameLocationsIds: this.map.start.quiz.user_answer !== null
            && this.map.locations.length >= 1
            && this.map.start.quiz.location_id !== null
                ? _.pluck(this.map.locations, 'id').toString()
                : String(this.map.start.quiz.location_id),
            userLocation: this.map.user.onMap.coordinates,
        }).then((response: any) => {
            this.locationResource.store(response);
        }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
            }
        });
    }

    private $t(key: string): TranslateResult {
        return i18n.t(key, this.currentLocale);
    }

    private handlerErrors(error: any): void {
        if (error.code === 1) {
            this.$store.dispatch('storeIsEnabledGeographicLocation', false);
            this.$store.dispatch('storeIsBusy', true);
            alert(this.$t('alert.error.location.disabled'));
        }

        if (error.code === 2) {
            this.$store.dispatch('storeIsEnabledGeographicLocation', false);
            this.$store.dispatch('storeIsBusy', true);
            alert(this.$t('alert.error.location.unavailable'));
        }
    }
}
