<template src='./order-cancel.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import axios from 'axios';

@Component({})
export default class OrderCancel extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    get user() {
        return this.$store.getters.user;
    }

    public created(): void {
        this.$store.dispatch('storeUser');
        this.$store.dispatch('storeCart');
    }

    public mounted() {
        this.$store.dispatch('destroyCart', {});
        this.destroyOrder();
    }

    private destroyOrder(): void {
        axios.delete(`${process.env.API_URL}/orders/${this.$route.params.id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.user.accessToken}`,
                },
            },
        ).then(response => {
            this.laravelErrorsService.destroyErrors();
            if (response.data.success) {
                this.$store.dispatch('destroyCart', {});
            }
        }).catch(errors => {
            if (errors.response.status === 404) {
                this.$router.push({
                    name: 'Games',
                });
            }

            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            this.$store.dispatch('destroyCart', {});

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }
}
</script>
<style lang='scss' scoped src='./order-cancel.scss'></style>
