<template src='./UserGameInvalidType.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';

@Component({})
export default class UserGameInvalidType extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    get user() {
        return this.$store.getters.user;
    }

    public created(): void {
        this.$store.dispatch('storeUser');
    }
}
</script>
<style lang='scss' scoped src='./UserGameInvalidType.scss'></style>
