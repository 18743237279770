import axios, { CancelToken, CancelTokenSource } from 'axios';
import LocaleService from '@/services/LocaleService';

export default class AxiosService {
    private user: Record<string, unknown> | never = {};

    constructor(authServiceUser: Record<string, unknown>) {
        this.user = authServiceUser;
    }

    public config(config: Record<string, unknown> = {}): Record<string, unknown> | never {
        return Object.assign(config, {
            headers: {
                Authorization: `Bearer ${this?.user?.accessToken}`,
                Locale: LocaleService.getCurrentLocale(),
            },
        });
    }

    public getCancelSource(): CancelTokenSource {
        return axios.CancelToken.source();
    }

    public getCancelToken(): CancelToken {
        return this.getCancelSource().token;
    }
}
