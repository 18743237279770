import UserGameRequest from '@/requests/UserGameRequest';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AuthService from '@/services/AuthService';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18';
import { Store } from 'vuex';
import ModalService from '@/services/ModalService';

export default class UserGameService {
    private $store: Store<unknown>;

    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private modalService: ModalService;

    private userGameRequest: UserGameRequest;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.authService = new AuthService(parent.$router, parent.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.modalService = new ModalService(parent);
        this.userGameRequest = new UserGameRequest(parent);
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public update(callback: any): void {
        this.userGameRequest.update(this.map.game.user_game.id, null, false)
            .then((response: any) => {
                this.modalService.hide('conformation-2');
                callback();
            }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private $t(key: string): TranslateResult {
        return i18n.t(key, this.currentLocale);
    }
}
