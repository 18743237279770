<template src='./login.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AuthService from '@/services/AuthService';
import iziToast from 'izitoast';
import AxiosService from '@/services/Axios/AxiosService';
import AssetService from '@/services/AssetService';
import UserGameParamsService from '@/services/UserGameParamsService';

@Component({})
export default class Login extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private axiosService: AxiosService;

    private assetService: AssetService;

    private userGameParamsService: UserGameParamsService;

    private form: Object = {
        email: null,
        password: null,
        game_key: null,
    };

    constructor(options: any) {
        super(options);
        this.laravelErrorsService = new LaravelErrorsService();
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.assetService = new AssetService();
        this.userGameParamsService = new UserGameParamsService(this);
    }

    get invalidEmail(): boolean {
        return typeof this.laravelErrorsService.errors.email === 'undefined';
    }

    get invalidPassword(): boolean {
        return typeof this.laravelErrorsService.errors.password === 'undefined';
    }

    get invalidGameKey(): boolean {
        return typeof this.laravelErrorsService.errors.game_key === 'undefined';
    }

    get user() {
        return this.$store.getters.user;
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private onSubmit(event: any): void {
        event.preventDefault();

        axios.post(`${process.env.API_URL}/login`,
            this.form,
            this.axiosService.config())
            .then((response: any) => {
                this.laravelErrorsService.destroyErrors();
                this.$store.dispatch('storeUser', response.data.user);
                this.userGameParamsService
                    .setParams(response.data.user.user_game_params)
                    .toIndividualUserGame()
                    .toTeamUserGame()
                    .toGames();
            })
            .catch((errors: any) => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }

    private onReset(event: any): void {
        event.preventDefault();
        this.form = {
            email: null,
            password: null,
            game_key: null,
        };
        this.laravelErrorsService.destroyErrors();
        iziToast.destroy();
    }
}
</script>
<style lang='scss' scoped src='./login.scss'></style>
