import UserGameParams from '@/contracts/UserGameParams';
import VueRouter from 'vue-router';

export default class UserGameParamsService {
    public userGameParams!: UserGameParams | null;

    public $router: VueRouter;

    constructor(parent: any) {
        this.$router = parent.$router;
    }

    public setParams(userGameParams: UserGameParams): UserGameParamsService {
        this.userGameParams = userGameParams;

        return this;
    }

    public toIndividualUserGame(): UserGameParamsService {
        if (this.userGameParams && this.userGameParams.game_type === 1) {
            this.$router.push({
                name: 'IndividualUserGame',
                params: {
                    gameId: String(this.userGameParams.game_id),
                    userGameId: String(this.userGameParams.user_game_id),
                },
            });
        }

        return this;
    }

    public toTeamUserGame(): UserGameParamsService {
        if (this.userGameParams && this.userGameParams.game_type === 2) {
            this.$router.push({
                name: 'TeamUserGame',
                params: {
                    gameId: String(this.userGameParams.game_id),
                    userGameId: String(this.userGameParams.user_game_id),
                    key: String(this.userGameParams.key),
                },
            });
        }

        return this;
    }

    public toGames(): UserGameParamsService {
        if (this.userGameParams === null) {
            this.$router.push('Games');
        }

        return this;
    }
}
