import { Store } from 'vuex';
import ValidateResponseService from '@/services/ValidateResponseService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import VueRouter from 'vue-router';

export default class QuizEndGameResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private validateResponseService: ValidateResponseService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
    }

    public index(response: any): void {
        this.laravelErrorsService.destroyErrors();
        // this.$store.dispatch('storeUserAnswer', response);
    }

    public show(response: any): void {
        this.laravelErrorsService.destroyErrors();
        this.$store.dispatch('storeEndGameLocations', response);
        this.$store.dispatch('storeIsBusy', false);
    }

    public store(response: any): void {
        console.log(response);
    }

    public destroy(response: any): void {
        console.log(response);
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }

    get game(): number {
        return this.$store.getters.game;
    }
}
