<template src='./UserGameExceedLimit.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import GameTypeService from '@/services/GameTypeService';

@Component({})
export default class UserGameExceedLimit extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    public resume() {
        if (Number(this.$route.params.teamKey) === GameTypeService.Individual) {
            this.$router.push({
                name: 'IndividualUserGame',
                params: {
                    gameId: this.$route.params.gameId,
                    userGameId: this.$route.params.userGameId,
                },
            });
        }

        if (this.$route.params.teamKey.length >= 2 && Number(this.$route.params.teamKey) !== GameTypeService.Individual) {
            this.$router.push({
                name: 'TeamUserGame',
                params: {
                    gameId: this.$route.params.gameId,
                    userGameId: this.$route.params.userGameId,
                    key: this.$route.params.teamKey,
                },
            });
        }
    }

    get user() {
        return this.$store.getters.user;
    }

    public created(): void {
        this.$store.dispatch('storeUser');
    }
}
</script>
<style lang='scss' scoped src='./UserGameExceedLimit.scss'></style>
