import axios from 'axios';
import AxiosService from '@/services/Axios/AxiosService';
import { Store } from 'vuex';

export default class AbstractRequest {
    public $store: Store<unknown>;

    public resource?: any = null;

    public url: string;

    public axiosService: AxiosService;

    constructor(parent: any, resource: string) {
        this.$store = parent.$store;
        this.resource = resource;
        this.url = `${process.env.API_URL}/${this.resource}`;
        this.axiosService = new AxiosService(this.user);
    }

    public async index(id: any = null, query: any = null, search: boolean = false): Promise<any> {
        return axios.get(this.getCustomUrl(id, query, search),
            this.axiosService.config(),
        );
    }

    public async show(id: any, query: any, search: boolean = false): Promise<any> {
        return axios.get(this.getCustomUrl(id, query, search),
            this.axiosService.config(),
        );
    }

    public async store(id: any, query: any, data: Object | any,  search: boolean = false): Promise<any> {
        return axios.post(this.getCustomUrl(id, query, search),
            data,
            this.axiosService.config(),
        );
    }

    public async update(id: any, query: any, search: boolean = false): Promise<any> {
        return axios.put(this.getCustomUrl(id, query, search),
            {},
            this.axiosService.config(),
        );
    }

    public async destroy(id: any, query: any): Promise<any> {
        return axios.delete(this.getCustomUrl(id, query),
            this.axiosService.config(),
        );
    }

    private getCustomUrl(id: any = null, query: any = null, search: boolean = false): string {
        let responseUrl: any = null;

        if (!id && !query && !search) {
            responseUrl = `${this.url}`;
        }

        if (id !== null) {
            responseUrl = `${this.url}/${id}`;
        }

        if (query !== null && !search) {
            responseUrl = `${this.url}/${query}`;
        }

        if (query !== null && search) {
            responseUrl = `${this.url}?${query.toString()}`;
        }

        return responseUrl;
    }

    get user(): any {
        return this.$store.getters.user;
    }
}
