<template src='./register.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import axios from 'axios';
import iziToast from 'izitoast';
import AxiosService from '@/services/Axios/AxiosService';

@Component({})
export default class Register extends Vue {
    private authService: AuthService;

    private axiosService: AxiosService;

    private laravelErrorsService: LaravelErrorsService;

    private form: Object | any = {
        user_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        language_id: 1,
        tos: false,
        languages: [],
    };

    private isBusy: boolean = false;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    public created(): void {
        this.languages();
    }

    public mounted(): void {
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private onSubmit(event: any, skip: boolean = false): void {
        if (!skip) {
            event.preventDefault();
        }

        axios.post(`${process.env.API_URL}/register`,
            this.form,
            this.axiosService.config())
            .then((response: any) => {
                this.laravelErrorsService.destroyErrors();
                this.$store.dispatch('storeUser', response.data.user);
                this.$router.push('Games');
            })
            .catch((errors: any) => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }

    private onReset(event: any): void {
        event.preventDefault();
        this.languages();
        this.form = {
            user_name: '',
            email: '',
            phone: '',
            password: '',
            password_confirmation: '',
            language_id: 1,
            gdpr: false,
            languages: [],
        };
        this.laravelErrorsService.destroyErrors();
        iziToast.destroy();
    }

    private languages(): void {
        axios.get(`${process.env.API_URL}/languages`,
            this.axiosService.config())
            .then((response: any) => {
                this.form.languages = response.data.map((item: any) => {
                    return {
                        text: item.name,
                        value: item.id,
                    };
                });
            })
            .catch((errors: any) => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }

    get invalidUserName(): boolean {
        return typeof this.laravelErrorsService.errors.user_name === 'undefined';
    }

    get invalidEmail(): boolean {
        return typeof this.laravelErrorsService.errors.email === 'undefined';
    }

    get invalidPhone(): boolean {
        return typeof this.laravelErrorsService.errors.phone === 'undefined';
    }

    get invalidPassword(): boolean {
        return typeof this.laravelErrorsService.errors.password === 'undefined';
    }

    get invalidPasswordConfirmation(): boolean {
        return typeof this.laravelErrorsService.errors.password_confirmation === 'undefined';
    }

    get invalidLanguage(): boolean {
        return typeof this.laravelErrorsService.errors.language_id === 'undefined';
    }

    get invalidTos(): boolean {
        return typeof this.laravelErrorsService.errors.tos === 'undefined';
    }

    get invalidGdpr(): boolean {
        return typeof this.laravelErrorsService.errors.gdpr === 'undefined';
    }

    get user() {
        return this.$store.getters.user;
    }
}
</script>
<style lang='scss' scoped src='./register.scss'></style>
