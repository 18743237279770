import UserAnswerRequest from '@/requests/UserAnswerRequest';
import UserAnswerResource from '@/resources/UserAnswerResource';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AuthService from '@/services/AuthService';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18';

export default class UserAnswerService {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private userAnswerRequest: UserAnswerRequest;

    private userAnswerResource: UserAnswerResource;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.userAnswerRequest = new UserAnswerRequest(this);
        this.userAnswerResource = new UserAnswerResource(this);
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public store(responseCallback: any): void {
        this.userAnswerRequest.store(null, null, {
            user_game_id: this.map.game.user_game.id,
            quiz_id: this.map.user.onMap.selected.answer.quiz_id,
            answer_id: this.map.user.onMap.selected.answer.id,
            text: this.map.user.onMap.selected.answer.text,
            location: typeof this.map.user.onMap.coordinates !== 'undefined'
                ? this.map.user.onMap.coordinates
                : null,
        }).then((response: any) => {
            if (response.data) {
                responseCallback();
            }
        }).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private $t(key: string): TranslateResult {
        return i18n.t(key, this.currentLocale);
    }
}
