import { render, staticRenderFns } from "./NotFound.html?vue&type=template&id=565ef146&scoped=true&external"
import script from "./NotFound.vue?vue&type=script&lang=ts"
export * from "./NotFound.vue?vue&type=script&lang=ts"
import style0 from "./NotFound.scss?vue&type=style&index=0&id=565ef146&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "565ef146",
  null
  
)

export default component.exports