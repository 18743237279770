import axios from 'axios';
import i18n from '@/i18';

export default class TranslationsService {
    public currentLocale: string = process.env.VUE_APP_I18N_FALLBACK_LOCALE;

    constructor(currentLocale: string) {
        this.currentLocale = currentLocale;
    }

    public async loadTranslationMessages(): Promise<void> {
        const response: any = await axios.get(`${process.env.API_URL}/translations`, {
            params: {
                locale: this.currentLocale,
            },
            headers: {
                Locale: this.currentLocale,
            },
        });

        i18n.setLocaleMessage(this.currentLocale, response.data[this.currentLocale]);
    }
}
