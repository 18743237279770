export default class MediaService {
    public YOUTUBE: string = 'youtube';

    public VIMEO: string = 'vimeo';

    public source(quiz: any, type: string): boolean {
        let response: boolean = false;

        if (quiz.video_url === null || quiz.video_source === null) {
            response = false;
        }

        if (quiz.video_source === this.YOUTUBE && quiz.video_source === type) {
            response = true;
        }

        if (quiz.video_source === this.VIMEO && quiz.video_source === type) {
            response = true;
        }

        return response;
    }
}
