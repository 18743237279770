<template>
    <div>
        <header>
            <b-navbar toggleable='lg'
                      type='light'
                      variant='light'>
                <b-navbar-brand href='/'>
                    <img :alt="$t('navbar.link.logo')"
                         :src='assetService.logo()'
                         height='32'>
                </b-navbar-brand>

                <b-navbar-toggle target='nav-collapse'></b-navbar-toggle>

                <b-collapse id='nav-collapse' is-nav>
                    <b-navbar-nav class='ml-auto'>
                        <b-nav-item-dropdown :text="$t('navbar.link.languages')" right>

                            <b-dropdown-item :active="currentLocale === 'lv'" href='#' @click="setLocale('lv')">
                                <country-flag country='lv' size='normal' />
                            </b-dropdown-item>

                            <b-dropdown-item :active="currentLocale === 'en'" href='#' @click="setLocale('en')">
                                <country-flag country='gb' size='normal' />
                            </b-dropdown-item>

                            <b-dropdown-item :active="currentLocale === 'ru'" href='#' @click="setLocale('ru')">
                                <country-flag country='rus' size='normal' />
                            </b-dropdown-item>

                        </b-nav-item-dropdown>

                        <router-link v-if='authService.guest()' class='nav-link' to='/login'>
                            {{ $t('navbar.link.login') }}
                        </router-link>

                        <router-link v-if='!authService.guest()' class='nav-link' to='/games'>
                            {{ $t('navbar.link.games') }}
                        </router-link>

                        <router-link class='nav-link' to='/faq'>
                            {{ $t('navbar.link.faq') }}
                        </router-link>

                        <router-link v-if='!authService.guest()' class='nav-link' to='/cart'>
                            {{ $t('navbar.link.cart') }}
                        </router-link>

                        <b-nav-item v-if='!authService.guest()'
                                    @click='authService.logout()'>
                            {{ $t('navbar.link.logout') }}
                        </b-nav-item>

                        <router-link v-if='authService.guest()' class='nav-link' to='/register'>
                            {{ $t('navbar.link.register') }}
                        </router-link>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </header>

        <main role='main'>
            <router-view class='mt-5' />
        </main>
    </div>
</template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import CountryFlag from 'vue-country-flag';
import AssetService from '@/services/AssetService';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave', 'metaInfo']);

@Component({
    components: {
        CountryFlag,
    },
})
export default class App extends Vue {
    private authService: AuthService;

    private assetService: AssetService;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.assetService = new AssetService();
    }

    public created() {
        this.$store.dispatch('storeUser');
        this.$store.dispatch('storeLocale');
        this.$i18n.locale = this.currentLocale;
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private setLocale(locale: string): void {
        this.$store.dispatch('storeLocale', locale);
        this.$i18n.locale = locale;
    }

    get user(): any {
        return this.$store.getters.user;
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }
}
</script>
<style>
.footer {
    display: block;
    position: fixed !important;
    bottom: 0;
    width: 100%;
    height: 60px;
}

.footer ul {
    display: inline-block;
    width: 100%;
    padding: 7px 0 0 0;
    margin: 0;
}

.footer li {
    float: left;
    width: 20%;
    display: inline-block;
    height: 100%;
}

.footer a > span {
    display: block;
    font-size: 12px;
    line-height: 20px;
}

.footer p {
    margin-bottom: .25rem;
}

.dropdown-menu {
    min-width: unset !important;
}
</style>
