import { router } from '@/plugins/vue-router';
import AuthService from '@/services/AuthService';
import store from '@/store';

const authService: AuthService = new AuthService(router, store);

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.auth)) {
        if (authService.guest()) {
            next({
                name: 'Login',
            });
        } else {
            next();
        }
    } else if (to.matched.some((record) => record.meta.guest)) {
        if (authService.guest()) {
            next();
        } else {
            next({
                name: 'Games',
            });
        }
    } else {
        next();
    }
});
