export default class ShareService {
    public $refs: any;

    constructor(parent: any) {
        this.$refs = parent.$refs;
    }

    public copy(ref: string, text: string, key: boolean = false) {
        if (key) {
            this.$refs[ref][0].focus();
        } else {
            this.$refs[ref].focus();
        }

        navigator.clipboard.writeText(text).then(() => {
            console.log('ShareService: copied to clipboard: successful!');
        }, function(err) {
            console.error('ShareService: could not copy to clipboard: ', err);
        });
    }

    public share(ref: string, text: string, key: boolean = false) {
        if (key) {
            this.$refs[ref][0].focus();
        } else {
            this.$refs[ref].focus();
        }

        if (navigator.share) {
            navigator.share({
                url: text,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }
}
