import { Store } from 'vuex';
import ModalService from '@/services/ModalService';

export default class GoogleMapWindowService {
    public $store: Store<unknown>;

    private modalService: ModalService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.modalService = new ModalService(parent);
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public marker(): void {
        this.map.start.quiz.user_answer === null
            ? this.store(this.map?.start?.location)
            : this.modalService.show(`start-complete-${this.map?.start?.quiz?.user_answer?.id}`);
    }

    public store(location: any): void {
        this.$store.dispatch('storeMapOnMapInfoWindowOpen', true);
        this.$store.dispatch('storeMapOnMapInfoWindowId', location.id);
        this.$store.dispatch('storeMapOnMapInfoWindowLocation', location);
        this.$store.dispatch('storeMapOnMapInfoWindowOptionContent', location.infoText);
    }

    public closed(): void {
        this.$store.dispatch('storeMapOnMapInfoWindowOpen', false);
    }
}
