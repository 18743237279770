import Pusher from 'pusher-js';
import { Store } from 'vuex';
import iziToast from 'izitoast';
import VueRouter from 'vue-router';
import GameTypeService from '@/services/GameTypeService';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18';

export default class PusherService {
    public $store: Store<unknown>;

    public $route: any;

    public $router: VueRouter;

    private $pusher!: Pusher;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$route = parent.$route;
        this.$router = parent.$router;
        this.$pusher = parent.$pusher;
    }

    get user(): any {
        return this.$store.getters.user;
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public async game(): Promise<void> {
        const gameChannel: any = this.$pusher.subscribe(`private-game-${this.$route.params.gameId}-unpublished`);

        await this.gameUnpublished(gameChannel);
    }

    public async team(callback: any): Promise<void> {
        const gameChannel: any = this.$pusher.subscribe(`private-game-${this.$route.params.gameId}-unpublished`);
        const teamChannel: any = this.$pusher.subscribe(`private-team-${this.$route.params.gameId}-${this.$route.params.userGameId}`);

        teamChannel.bind('pusher:subscription_count', (data: any) => {
            const subscribers: number = data.subscription_count;
            const additionalSeats: number = 2;
            const availableSeats = Number(this.map.game.user_game.contestants * additionalSeats);

            if (typeof this.map.game.user_game.contestants !== 'undefined' && subscribers > availableSeats) {
                this.$router.push({
                    name: 'UserGameExceedLimit',
                    params: {
                        gameId: this.map.game.user_game.game_id,
                        userGameId: this.map.game.user_game.id,
                        key: this.map.game.user_game.game_type_id === GameTypeService.Team
                            ? this.map.game.user_game.team.key
                            : GameTypeService.Individual,
                    },
                });
            }
        });

        await this.gameUnpublished(gameChannel);
        await this.teamUserAnswer(teamChannel, callback);
        await this.teamQuickResponseCodeRegisteredUser(teamChannel, callback);
        await this.teamCaptainEndsGame(teamChannel, callback);
    }

    private async gameUnpublished(channel: Pusher): Promise<void> {
        (channel as Pusher).bind('GameUnpublished', async (payload: any) => {
            if (payload) {
                iziToast.error({
                    timeout: 50000,
                    position: 'topRight',
                    message: String(this.$t('game.unpublished', { name: payload.name })),
                });

                this.$router.push({
                    name: 'GameUnpublished',
                });
            }
        });
    }

    private async teamCaptainEndsGame(channel: Pusher, callback: any): Promise<void> {
        (channel as Pusher).bind('TeamCaptainEndsGame', async (payload: any) => {
            if (payload) {
                callback();
                iziToast.success({
                    timeout: 12000,
                    position: 'topRight',
                    message: String(this.$t('game.user_game.team_captain_ends_game', {
                        user_name: payload.user_name,
                    })),
                });
            }
        });
    }

    private async teamUserAnswer(channel: Pusher, callback: any): Promise<void> {
        (channel as Pusher).bind('TeamUserAnswer', async (payload: any) => {
            if (payload) {
                callback();
                iziToast.success({
                    timeout: 12000,
                    position: 'topRight',
                    message: String(this.$t('game.user_game.player.discovered.location', {
                        user_name: payload.user_name,
                    })),
                });
            }
        });
    }

    private async teamQuickResponseCodeRegisteredUser(channel: Pusher, callback: any): Promise<void> {
        (channel as Pusher).bind('QuickResponseCodeRegisteredUser', async (payload: any) => {
            if (payload) {
                callback();
                iziToast.success({
                    timeout: 12000,
                    position: 'topRight',
                    message: String(this.$t('game.user_game.quick_response_code_registered_user')),
                });
            }
        });
    }

    private $t(key: string, values: any[] | { [key: string]: any } = {}): TranslateResult {
        return i18n.t(key, this.currentLocale, values);
    }
}
