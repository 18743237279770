export default class AssetService {
    public url: string = String(process.env.API_BASE_URL);

    public logo(): string {
        return `${this.url}/other/logo.png`;
    }

    public inProgress(): string {
        return `${this.url}/other/norises.jpg`;
    }

    public partnerWvr(): string {
        return `${this.url}/other/wvr.png`;
    }

    public partnerTimeMachine(): string {
        return `${this.url}/other/timemachine.png`;
    }
}
