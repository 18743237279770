import { Store } from 'vuex';
import VueRouter from 'vue-router';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import ValidateResponseService from '@/services/ValidateResponseService';
import ModalService from '@/services/ModalService';

export default class LocationResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private laravelErrorsService: LaravelErrorsService;

    private validateResponseService: ValidateResponseService;

    private modalService: ModalService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
        this.modalService = new ModalService(parent);
    }

    public index(response: any): void {
        console.log(response);
    }

    public store(response: any): void {
        const responseData: Object = response.data.length >= 1
            ? response.data[0]
            : {};

        this.laravelErrorsService.destroyErrors();
        this.$store.dispatch('storeMapUserLocation', { ...responseData });

        if (this.map.user.onMap.location.distance_in_meters <= 20.00) {
            this.modalService.show(this.map.user.onMap.location.id);
        }
    }

    public show(response: any): void {
        console.log(response);
    }

    public destroy(response: any): void {
        console.log(response);
    }

    get map(): any {
        return this.$store.getters.map;
    }
}
