<style src='./pagination.scss' lang='scss' scoped></style>
<template src='./pagination.html'></template>
<script lang='ts'>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import paginate from 'jw-paginate';

const defaultLabels = {
    first: 'First',
    last: 'Last',
    previous: 'Previous',
    next: 'Next',
};

const defaultStyles = {
    ul: {
        margin: 0,
        padding: 0,
        display: 'inline-block',
    },
    li: {
        listStyle: 'none',
        display: 'inline',
        textAlign: 'center',
    },
    a: {
        cursor: 'pointer',
        padding: '6px 12px',
        display: 'block',
        float: 'left',
    },
};

@Component({})
export default class Pagination extends Vue {
    constructor(options: any) {
        super(options);
    }

    @Prop({ default: [] })
    private data!: any;

    @Prop({ default: 1 })
    private initialPage!: number;

    @Prop({ default: 10 })
    private pageSize!: number;

    @Prop({ default: 10 })
    private maxPages!: number;

    @Prop({ default: 10 })
    private labels!: Object;

    @Prop({})
    private styles!: Object;

    @Prop({ default: false })
    private disableDefaultStyles!: boolean;

    @Prop({ default: false })
    private headerPagination!: boolean;

    @Prop({ default: true })
    private footerPagination!: boolean;

    private pager: any = {};

    private ulStyles: any = {};

    private liStyles: any = {};

    private aStyles: any = {};

    public created() {
        if (!this.$listeners.changePage) {
            throw 'Missing required event listener: "changePage"';
        }

        // set default styles unless disabled
        if (!this.disableDefaultStyles) {
            this.ulStyles = defaultStyles.ul;
            this.liStyles = defaultStyles.li;
            this.aStyles = defaultStyles.a;
        }

        // set to initial page
        this.setPage(this.initialPage);
    }

    setPage(page: number) {
        // get new pager object for specified page
        const pager: any = paginate(this.data.length, page, this.pageSize, this.maxPages);

        // get new page of items from items array
        const pageOfItems: any = this.data.slice(pager.startIndex, pager.endIndex + 1);

        // update pager
        this.pager = pager;

        this?.$parent?.$emit('paginationPager', pager);

        // emit change page event to parent component
        this?.$emit('changePage', pageOfItems);
    }

    @Watch('data')
    onItems(val: string, oldVal: string) {
        this.setPage(this?.initialPage);
    }
}
</script>
