<template src='./IndividualEndgame.html'></template>
<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import axios from 'axios';
// @ts-ignore
import ucwords from 'ucwords/src/ucwords';
import GameCityRequest from '@/requests/GameCityRequest';
import GameCityResource from '@/resources/GameCityResource';
import UserGameRequest from '@/requests/UserGameRequest';
import UserGamesResource from '@/resources/UserGameResource';
import UserGameResource from '@/resources/UserGameResource';
import QuizRequest from '@/requests/QuizRequest';
import QuizResource from '@/resources/QuizResource';
import QuizEndGameResource from '@/resources/QuizEndGameResource';
import GoogleMapService from '@/services/GoogleMapService';
import PromoRequest from '@/requests/PromoRequest';
import PromoResource from '@/resources/PromoResource';
import PromoService from '@/services/PromoService';
import UserAnswerRequest from '@/requests/UserAnswerRequest';
import UserAnswerResource from '@/resources/UserAnswerResource';
import UserGameExpiredRequest from '@/requests/UserGameExpiredRequest';
import UserGameExpiredResource from '@/resources/UserGameExpiredResource';
import PusherService from '@/services/PusherService';

@Component({})
export default class IndividualEndgame extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private googleMapService: GoogleMapService;

    private promoService: PromoService;

    private gameCityRequest: GameCityRequest;

    private gameCityResource: GameCityResource;

    private userGameRequest: UserGameRequest;

    private userGameResource: UserGameResource;

    private quizRequest: QuizRequest;

    private quizResource: QuizResource;

    private quizEndGameResource: QuizEndGameResource;

    private promoRequest: PromoRequest;

    private promoResource: PromoResource;

    private userAnswerRequest: UserAnswerRequest;

    private userAnswerResource: UserAnswerResource;

    private userGameExpiredRequest: UserGameExpiredRequest;

    private userGameExpiredResource: UserGameExpiredResource;

    private pusherService: PusherService;

    private appDebug!: string;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.googleMapService = new GoogleMapService();
        this.promoService = new PromoService(this);
        this.gameCityRequest = new GameCityRequest(this);
        this.gameCityResource = new GameCityResource(this);
        this.userGameRequest = new UserGameRequest(this);
        this.userGameResource = new UserGamesResource(this);
        this.quizRequest = new QuizRequest(this);
        this.quizResource = new QuizResource(this);
        this.quizEndGameResource = new QuizEndGameResource(this);
        this.promoRequest = new PromoRequest(this);
        this.promoResource = new PromoResource(this);
        this.userAnswerRequest = new UserAnswerRequest(this);
        this.userAnswerResource = new UserAnswerResource(this);
        this.userGameExpiredRequest = new UserGameExpiredRequest(this);
        this.userGameExpiredResource = new UserGameExpiredResource(this);
        this.pusherService = new PusherService(this);
        this.appDebug = process.env.APP_DEBUG;
    }

    get map(): boolean {
        return this.$store.getters.map;
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get user(): any {
        return this.$store.getters.user;
    }

    get gameCity(): any {
        return this.$store.getters.gameCity;
    }

    get game(): any {
        return this.$store.getters.game;
    }

    get endGame(): any {
        return this.$store.getters.endGame;
    }

    public async created(): Promise<void> {
        await this.$store.dispatch('storeUser');
        await this.$store.dispatch('storeMapGame', {
            'id': parseInt(this.$route.params.gameId),
            'user_game': {
                'id': parseInt(this.$route.params.userGameId),
            },
        });
        await this.collectAll();
    }

    public async mounted(): Promise<void> {
        await this.pusherService.game();
    }

    @Watch('endGame.isPromoEligible', { immediate: true, deep: true })
    onPromoEligible(eligible: boolean) {
        if (eligible) {
            this.promoService.update();
        }
    }

    private async collectAll(): Promise<void> {
        axios.all([
            this.gameCityRequest.show(this.game.id, null),
            this.userGameRequest.show(null, `${this.game.id}/${this.game.user_game.id}/start`),
            this.userGameRequest.show(null, `${this.game.id}/${this.game.user_game.id}`),
            this.quizRequest.show(null, `${this.game.id}/${this.game.user_game.id}`),
            this.quizRequest.show(null, `${this.game.id}/${this.game.user_game.id}/endgame`),
            this.userGameExpiredRequest.show(null, new URLSearchParams({
                'user_game_id': this.game.user_game.id,
            }), true),
            this.userAnswerRequest.show(this.game.user_game.id, null),
        ]).then(
            axios.spread((...responses) => {
                this.gameCityResource.show(responses[0].data);
                this.userGameResource.show(responses[1].data, true);
                this.userGameResource.show(responses[2].data, false);
                this.quizResource.show(responses[3].data);
                this.quizEndGameResource.show(responses[4].data);
                this.userGameExpiredResource.index(responses[5].data, true);
                this.userAnswerResource.show(responses[6].data, true);
            }),
        )
            .catch((errors: any) => {
                if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                    this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
                }

                if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                    this.laravelErrorsService.storeErrors(errors.response.data.errors);
                    this.laravelErrorsService.showErrorNotifications();
                }
            });
    }
}
</script>
<style lang='scss' scoped src='./IndividualEndgame.scss'></style>
