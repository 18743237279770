import iziToast from 'izitoast';

export default class LaravelErrorsService {
    public rawErrors!: any;

    public errors: any = {};

    public showErrorNotifications(): void {
        for (const [key, value] of Object.entries(this.rawErrors)) {
            iziToast.error({
                timeout: 12000,
                position: 'topRight',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                message: `${value[0]}`,
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public storeErrors(errors: any): void {
        this.rawErrors = errors;
        errors = Object.entries(errors);
        errors = errors
            .map((error: never[][]) => {
                const key: any = error[0];
                const value = error[1][0];
                return {
                    [key]: value,
                };
            })
            .reduce((acc: never, current: never) => {
                acc = Object.assign(acc, current);
                return acc;
            });

        this.setErrors(errors);
    }

    public destroyErrors(): void {
        this.errors = {};
    }

    private setErrors(errors: any): void {
        this.errors = errors;
    }
}
