import { render, staticRenderFns } from "./UserGameExpired.html?vue&type=template&id=bfbbb5c8&scoped=true&external"
import script from "./UserGameExpired.vue?vue&type=script&lang=ts"
export * from "./UserGameExpired.vue?vue&type=script&lang=ts"
import style0 from "./UserGameExpired.scss?vue&type=style&index=0&id=bfbbb5c8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfbbb5c8",
  null
  
)

export default component.exports