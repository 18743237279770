import { Store } from 'vuex';
import ValidateResponseService from '@/services/ValidateResponseService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import VueRouter from 'vue-router';

export default class UserGameResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private validateResponseService: ValidateResponseService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
    }

    public show(responses: any, isStartLocation: boolean = false): void {
        let response: any = responses[0];
        this.validate(responses, response);
        this.laravelErrorsService.destroyErrors();

        if (isStartLocation) {
            const gameStartLocation: any = response.game.game_location.location;

            this.$store.dispatch('storeMapStart', {
                location: {
                    id: gameStartLocation.id,
                    is_enabled: gameStartLocation.is_enabled,
                    lat: parseFloat(gameStartLocation.latitude),
                    lng: parseFloat(gameStartLocation.longitude),
                    description: gameStartLocation.description,
                    radius: parseFloat(gameStartLocation.radius_in_meters),
                    created_at: gameStartLocation.created_at,
                    updated_at: gameStartLocation.updated_at,
                    order: parseFloat(response.game.game_location.order),
                    infoText: `<img class='img-fluid' src='${gameStartLocation.media_url}' alt='${gameStartLocation.description}' width='200' height='200'>`,
                },
                quiz: {
                    ...response.game.game_location.location.quiz,
                },
            });
        } else {
            this.$store.dispatch('storeMapGame', {
                    ...response.game,
                    'user_game': {
                        ...response.order.user_game,
                    },
                    quick_response_code_forgot_user_names: response.quick_response_code_forgot_user_names,
                },
            );
            if (this.validateResponseService.isPromoEligible()) {
                this.$store.dispatch('storeEndGamePromoEligible', true);
            }

            this.$store.dispatch('storeMapLocations', response.game.game_locations.map((gameLocation: any) => {
                let location: any = gameLocation.location;

                return {
                    id: location.id,
                    is_enabled: location.is_enabled,
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                    description: location.description,
                    radius: parseFloat(location.radius_in_meters),
                    created_at: location.created_at,
                    updated_at: location.updated_at,
                    order: gameLocation.order,
                    infoText: gameLocation.order
                        ? `<img class='img-fluid' src='${location.media_url}' alt='${location.description}' width='200' height='200'>`
                        : null,
                };
            }));
            this.$store.dispatch('storeMapLocationsQuizzes', response.game.game_locations);
        }

        this.$store.dispatch('storeIsBusy', false);
    }

    public store(response: any): void {
        console.log(response);
    }

    public destroy(response: any): void {
        console.log(response);
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }

    public index(response: any): void {
        console.log(response);
    }

    private validate(responses: any, response: any): void {
        this.validateResponseService.isEmptyResponse(responses);
        this.validateResponseService.isEmptyOrder(response.order);
        this.validateResponseService.isEmptyTeam(response.team);
    }
}
