import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocaleService from '@/services/LocaleService';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: LocaleService.getCurrentLocale() || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    fallbackRoot: true,
});

export default i18n;
