import VueRouter from 'vue-router';
import iziToast from 'izitoast';
import axios from 'axios';
import { Store } from 'vuex';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import i18n from '../i18';
import { TranslateResult } from 'vue-i18n';
import AxiosService from '@/services/Axios/AxiosService';

export default class AuthService {
    public $router: VueRouter;

    public $store: Store<unknown>;

    private laravelErrorsService: LaravelErrorsService;

    private axiosService: AxiosService;

    constructor(router: VueRouter, store: Store<unknown>) {
        this.$router = router;
        this.$store = store;
        this.$store.dispatch('storeUser');
        this.laravelErrorsService = new LaravelErrorsService();
        this.axiosService = new AxiosService(this.user);
    }

    get currentLocale(): string {
        return this.$store.getters.currentLocale;
    }

    get user(): any {
        return this.$store.getters.user;
    }

    public logout(): void {
        if (this.guest()) {
            iziToast.error({
                timeout: 6000,
                position: 'topRight',
                message: String(this.$t('notification.error.logout')),
            });
        } else {
            axios
                .delete(`${process.env.API_URL}/logout`, this.axiosService.config())
                .then((response) => {
                    if (response.data.success) {
                        this.$store.dispatch('destroyUser', {});

                        iziToast.success({
                            timeout: 6000,
                            position: 'topRight',
                            message: String(this.$t('notification.success.logout')),
                        });

                        location.reload();
                    }
                })
                .catch((errors) => {
                    if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                        this.laravelErrorsService.storeErrors(errors.response.data.errors);
                        this.laravelErrorsService.showErrorNotifications();
                    }

                    if (errors.response.status === 401) {
                        this.forceLogout(null);
                    }
                });
        }
    }

    public forceLogout(message: string | null, exceptionMessage: string | null = null): void {
        if (message) {
            iziToast.error({
                timeout: 6000,
                position: 'topRight',
                message: `${message}`,
            });
        }

        this.$store.dispatch('destroyUser', {});

        this.$router.push({
            name: 'Home',
        });
    }

    public guest(): boolean {
        return this.user === null;
    }

    private $t(key: string): TranslateResult {
        return i18n.t(key, this.currentLocale);
    }
}
