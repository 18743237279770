<template src='./games.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AxiosService from '@/services/Axios/AxiosService';

@Component({})
export default class Games extends Vue {
    private authService: AuthService;

    private axiosService: AxiosService;

    private laravelErrorsService: LaravelErrorsService;

    private gamesData: Iterable<Object> | any = [];

    private isBusy: boolean = true;

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    public created(): void {
        this.$store.dispatch('storeUser');
        this.games();
    }

    public mounted(): void {
        //
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private games(): void {
        axios.get(`${process.env.API_URL}/games`,
            this.axiosService.config(),
        ).then(response => {
            this.isBusy = false;
            this.gamesData = response.data;
        }).catch(errors => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private goToGame(game: any): void {
        this.$router.push({
            name: 'GamesShow',
            params: {
                id: game.id,
            },
        });
    }

    get user() {
        return this.$store.getters.user;
    }
}
</script>
<style lang='scss' scoped src='./games.scss'></style>
