import Vue from 'vue';
import Vuex from 'vuex';
import TranslationsService from '@/services/TranslationsService';
import LocaleService from '@/services/LocaleService';

Vue.use(Vuex);

const locale: string = LocaleService.getCurrentLocale();

export default new Vuex.Store({
    state: {
        component: null,
        user: null,
        cart: {},
        locale: locale,
        map: {
            isEnabledGeographicLocation: false,
            isBusy: true,
            isAnswering: false,
            isThankYou: false,
            isUserGameExpired: false,
            isUserGameAnswersComplete: false,
            gameCity: {
                id: 0,
                name: null,
                lat: 0,
                lng: 0,
                is_enabled: false,
                slug: null,
                created_at: null,
                updated_at: null,
            },
            game: {
                user_game: {
                    team: {},
                    game_type: {},
                },
            },
            start: {
                location: {},
                quiz: {},
            },
            locations: [],
            quizzes: [],
            user: {
                answers: {},
                onMap: {
                    selected: {
                        answer: {
                            id: null,
                            answer_id: null,
                            text: null,
                        },
                    },
                    location: {},
                    coordinates: null,
                    formatted: {
                        coordinates: {},
                    },
                    info: {
                        window: {
                            id: null,
                            open: false,
                            options: {
                                content: '',
                                pixelOffset: {
                                    width: 0,
                                    height: -35,
                                },
                            },
                            location: null,
                        },
                    },
                },
            },
        },
        endGame: {
            isPromoEligible: false,
            tableHeaders: ['Jautājums', 'Mana atbilde', 'Pareizā atbilde'],
            quizzes: {},
            locations: {},
            statistics: [],
            userAnswers: [],
        },
    },
    mutations: {
        storeComponent(state: any, payload: any): void {
            state.component = payload;
        },
        storeUser(state: any, payload: any): void {
            state.user = payload;
        },
        storeCart(state: any, payload: any): void {
            state.cart = payload;
        },
        async storeLocale(state: any, payload: any): Promise<any> {
            const translationService: TranslationsService = new TranslationsService(payload);
            await translationService.loadTranslationMessages();

            state.locale = payload;
        },
        storeIsEnabledGeographicLocation(state: any, payload: any): void {
            state.map.isEnabledGeographicLocation = payload;
        },
        storeIsBusy(state: any, payload: any): void {
            state.map.isBusy = payload;
        },
        storeIsThankYou(state: any, payload: any): void {
            state.map.isThankYou = payload;
        },
        storeIsAnswering(state: any, payload: any): void {
            state.map.isAnswering = payload;
        },
        storeIsUserGameExpired(state: any, payload: any): void {
            state.map.isUserGameExpired = payload;
        },
        storeIsUserGameAnswerComplete(state: any, payload: any): void {
            state.map.isUserGameAnswersComplete = payload;
        },
        storeMapGameCity(state: any, payload: any): void {
            state.map.gameCity = payload;
        },
        storeMapGame(state: any, payload: any): void {
            state.map.game = payload;
        },
        storeMapStart(state: any, payload: any): void {
            state.map.start = payload;
        },
        storeMapLocations(state: any, payload: any): void {
            state.map.locations = payload;
        },
        storeMapLocationsQuizzes(state: any, payload: any): void {
            state.map.quizzes = payload;
        },
        storeMapUserAnswers(state: any, payload: any): void {
            state.map.user.answers = payload;
        },
        storeMapUserLocation(state: any, payload: any): void {
            state.map.user.onMap.location = payload;
        },
        storeMapUserCoordinates(state: any, payload: any): void {
            state.map.user.onMap.coordinates = payload;
        },
        storeMapOnMapCoordinates(state: any, payload: any): void {
            state.map.user.onMap.formatted.coordinates = payload;
        },
        storeMapOnMapSelectedAnswer(state: any, payload: any): void {
            state.map.user.onMap.selected.answer = payload;
        },
        storeMapOnMapInfoWindowOpen(state: any, payload: any): void {
            state.map.user.onMap.info.window.open = payload;
        },
        storeMapOnMapInfoWindowId(state: any, payload: any): void {
            state.map.user.onMap.info.window.id = payload;
        },
        storeMapOnMapInfoWindowLocation(state: any, payload: any): void {
            state.map.user.onMap.info.window.location = payload;
        },
        storeMapOnMapInfoWindowOptionContent(state: any, payload: any): void {
            state.map.user.onMap.info.window.options.content = payload;
        },
        storeEndGameQuizzes(state: any, payload: any): void {
            state.endGame.quizzes = payload;
        },
        storeEndGameLocations(state: any, payload: any): void {
            state.endGame.locations = payload;
        },
        storeEndGameStatistics(state: any, payload: any): void {
            state.endGame.statistics = payload;
        },
        storeEndGameUserAnswers(state: any, payload: any): void {
            state.endGame.userAnswers = payload;
        },
        storeEndGamePromoEligible(state: any, payload: any): void {
            state.endGame.isPromoEligible = payload;
        },
    },
    actions: {
        storeComponent: function(context: any, payload: any) {
            context.commit('storeComponent', payload);
        },
        storeUser: function(context: any, payload: any) {
            if (payload) {
                localStorage.setItem('user', JSON.stringify(payload));

                context.commit('storeUser', payload);
            } else {
                const localStorageUser: any = JSON.parse(<string>localStorage.getItem('user'));

                localStorage.setItem('user', JSON.stringify(localStorageUser));
                context.commit('storeUser', localStorageUser);
            }
        },
        destroyUser: function(context: any, payload: any) {
            localStorage.removeItem('user');
            context.commit('storeUser', payload);
        },
        storeCart: function(context: any, payload: any) {
            if (payload) {
                localStorage.setItem('cart', JSON.stringify(payload));
                context.commit('storeCart', payload);
            } else {
                const localStorageCart = JSON.parse(<string>localStorage.getItem('cart'));

                localStorage.setItem('cart', JSON.stringify(localStorageCart));
                context.commit('storeCart', localStorageCart);
            }
        },
        destroyCart: function(context: any, payload: any) {
            localStorage.removeItem('cart');
            context.commit('storeCart', payload);
        },
        storeLocale: async function(context: any, payload: any) {
            if (payload) {
                localStorage.setItem('locale', payload);
                context.commit('storeLocale', payload);
            } else {
                localStorage.setItem('locale', locale);
                context.commit('storeLocale', locale);
            }
        },
        storeIsEnabledGeographicLocation: function(context: any, payload: any) {
            context.commit('storeIsEnabledGeographicLocation', payload);
        },
        storeIsBusy: function(context: any, payload: any) {
            context.commit('storeIsBusy', payload);
        },
        storeIsThankYou: function(context: any, payload: any) {
            context.commit('storeIsThankYou', payload);
        },
        storeIsAnswering: function(context: any, payload: any) {
            context.commit('storeIsAnswering', payload);
        },
        storeIsUserGameExpired: function(context: any, payload: any) {
            context.commit('storeIsUserGameExpired', payload);
        },
        storeIsUserGameAnswerComplete: function(context: any, payload: any) {
            context.commit('storeIsUserGameAnswerComplete', payload);
        },
        storeMapGameCity: function(context: any, payload: any) {
            context.commit('storeMapGameCity', payload);
        },
        storeMapGame: function(context: any, payload: any) {
            context.commit('storeMapGame', payload);
        },
        storeMapStart: function(context: any, payload: any) {
            context.commit('storeMapStart', payload);
        },
        storeMapLocations: function(context: any, payload: any) {
            context.commit('storeMapLocations', payload);
        },
        storeMapLocationsQuizzes: function(context: any, payload: any) {
            context.commit('storeMapLocationsQuizzes', payload);
        },
        storeMapUserAnswers: function(context: any, payload: any) {
            context.commit('storeMapUserAnswers', payload);
        },
        storeMapUserLocation: function(context: any, payload: any) {
            context.commit('storeMapUserLocation', payload);
        },
        storeMapUserCoordinates: function(context: any, payload: any) {
            context.commit('storeMapUserCoordinates', payload);
        },
        storeMapOnMapCoordinates: function(context: any, payload: any) {
            context.commit('storeMapOnMapCoordinates', payload);
        },
        storeMapOnMapSelectedAnswer: function(context: any, payload: any) {
            context.commit('storeMapOnMapSelectedAnswer', payload);
        },
        storeMapOnMapInfoWindowOpen: function(context: any, payload: any) {
            context.commit('storeMapOnMapInfoWindowOpen', payload);
        },
        storeMapOnMapInfoWindowId: function(context: any, payload: any) {
            context.commit('storeMapOnMapInfoWindowId', payload);
        },
        storeMapOnMapInfoWindowLocation: function(context: any, payload: any) {
            context.commit('storeMapOnMapInfoWindowLocation', payload);
        },
        storeMapOnMapInfoWindowOptionContent: function(context: any, payload: any) {
            context.commit('storeMapOnMapInfoWindowOptionContent', payload);
        },
        storeEndGameQuizzes: function(context: any, payload: any) {
            context.commit('storeEndGameQuizzes', payload);
        },
        storeEndGameLocations: function(context: any, payload: any) {
            context.commit('storeEndGameLocations', payload);
        },
        storeEndGameStatistics: function(context: any, payload: any) {
            context.commit('storeEndGameStatistics', payload);
        },
        storeEndGameUserAnswers: function(context: any, payload: any) {
            context.commit('storeEndGameUserAnswers', payload);
        },
        storeEndGamePromoEligible: function(context: any, payload: any) {
            context.commit('storeEndGamePromoEligible', payload);
        },
    },
    getters: {
        component(state: any): any {
            return state.component;
        },
        currentCart(state: any): any {
            return state.cart;
        },
        user(state: any): any {
            return state.user;
        },
        currentLocale(state: any): any {
            return state.locale;
        },
        isEnabledGeographicLocation(state: any): any {
            return state.map.isEnabledGeographicLocation;
        },
        isBusy(state: any): any {
            return state.map.isBusy;
        },
        isThankYou(state: any): any {
            return state.map.isThankYou;
        },
        isAnswering(state: any): any {
            return state.map.isAnswering;
        },
        gameCity(state: any): any {
            return state.map.gameCity;
        },
        game(state: any): any {
            return state.map.game;
        },
        mapStart(state: any): any {
            return state.map.start;
        },
        mapLocations(state: any): any {
            return state.map.locations;
        },
        mapLocationsQuizzes(state: any): any {
            return state.map.quizzes;
        },
        userAnswers(state: any): any {
            return state.map.userAnswers;
        },
        map(state: any): any {
            return state.map;
        },
        endGame(state: any): any {
            return state.endGame;
        },
    },
});
