import { Store } from 'vuex';

export default class AnswerService {
    public $store: Store<unknown>;

    constructor(parent: any) {
        this.$store = parent.$store;
    }

    public store(answer: any): void {
        this.$store.dispatch('storeMapOnMapSelectedAnswer', answer);
    }
}
