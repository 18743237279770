import { Store } from 'vuex';
import VueRouter from 'vue-router';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import ValidateResponseService from '@/services/ValidateResponseService';

export default class GameCityResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private validateResponseService: ValidateResponseService;

    private laravelErrorsService: LaravelErrorsService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
    }

    public index(response: any): void {
        console.log(response);
    }

    public show(response: any): void {
        const city: any = response.city;

        this.$store.dispatch('storeMapGameCity', {
            id: city.id,
            name: city.name,
            lat: city.latitude,
            lng: city.longitude,
            is_enabled: city.is_enabled,
            slug: city.slug,
            created_at: city.created_at,
            updated_at: city.updated_at,
        });
    }

    public store(response: any): void {
        console.log(response);
    }

    public destroy(response: any): void {
        console.log(response);
    }

    get gameCity(): any {
        return this.$store.getters.gameCity;
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }
}
