import { Store } from 'vuex';
import ValidateResponseService from '@/services/ValidateResponseService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import VueRouter from 'vue-router';
import ModalService from '@/services/ModalService';

export default class UserAnswerResource {
    public $store: Store<unknown>;

    public $router: VueRouter;

    private laravelErrorsService: LaravelErrorsService;

    private validateResponseService: ValidateResponseService;

    private modalService: ModalService;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
        this.laravelErrorsService = new LaravelErrorsService();
        this.validateResponseService = new ValidateResponseService(parent);
        this.modalService = new ModalService(parent);
    }

    get map(): any {
        return this.$store.getters.map;
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }

    get game(): any {
        return this.$store.getters.game;
    }

    public index(response: any): void {
        this.laravelErrorsService.destroyErrors();
    }

    public store(locationId: number | string | null = null): void {
        this.laravelErrorsService.destroyErrors();
    }

    public destroy(response: any): void {
        console.log(response);
    }

    public show(response: any, endgame: boolean = false): void {
        const complete: boolean = response.complete;
        this.laravelErrorsService.destroyErrors();
        this.$store.dispatch('storeMapUserAnswers', complete);

        if (complete && !endgame) {
            this.$store.dispatch('storeIsUserGameAnswerComplete', true);
            this.$store.dispatch('storeIsBusy', true);
            this.validateResponseService.redirectEndgameType();
        }
    }
}
