<template src='./TeamUserGame.html'></template>
<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import axios from 'axios';
// @ts-ignore
import Media from '@dongido/vue-viaudio';
import AssetService from '@/services/AssetService';
import MediaService from '@/services/MediaService';
import GoogleMapService from '@/services/GoogleMapService';
import GoogleMapWindowService from '@/services/GoogleMapWindowService';
import GameCityRequest from '@/requests/GameCityRequest';
import GameCityResource from '@/resources/GameCityResource';
import UserGameRequest from '@/requests/UserGameRequest';
import UserGameResource from '@/resources/UserGameResource';
import UserGamesResource from '@/resources/UserGameResource';
import UserGameExpiredRequest from '@/requests/UserGameExpiredRequest';
import UserGameExpiredResource from '@/resources/UserGameExpiredResource';
import UserAnswerRequest from '@/requests/UserAnswerRequest';
import UserAnswerResource from '@/resources/UserAnswerResource';
import UserAnswerService from '@/services/UserAnswerService';
import LocationService from '@/services/LocationService';
import ModalService from '@/services/ModalService';
import AnswerService from '@/services/AnswerService';
import UserGameService from '@/services/UserGameService';
import PusherService from '@/services/PusherService';
import ShareService from '@/services/ShareService';
import GameTypeService from '@/services/GameTypeService';
import GameTypeValidationRequest from '@/requests/GameTypeValidationRequest';
import GameTypeValidationResource from '@/resources/GameTypeValidationResource';

@Component({
    components: {
        Media,
    },
})
export default class TeamUserGame extends Vue {
    private authService: AuthService;

    private laravelErrorsService: LaravelErrorsService;

    private googleMapService: GoogleMapService;

    private googleMapWindowService: GoogleMapWindowService;

    private mediaService: MediaService;

    private gameCityRequest: GameCityRequest;

    private gameCityResource: GameCityResource;

    private userGameRequest: UserGameRequest;

    private userGameResource: UserGameResource;

    private userGameExpiredRequest: UserGameExpiredRequest;

    private userGameExpiredResource: UserGameExpiredResource;

    private userAnswerRequest: UserAnswerRequest;

    private userAnswerResource: UserAnswerResource;

    private gameTypeValidationRequest: GameTypeValidationRequest;

    private gameTypeValidationResource: GameTypeValidationResource;

    private userAnswerService: UserAnswerService;

    private locationService: LocationService;

    private modalService: ModalService;

    private assetService: AssetService;

    private answerService: AnswerService;

    private pusherService: PusherService;

    private userGameService: UserGameService;

    private gameTypeService: GameTypeService;

    private shareService: ShareService;

    private onMap: any = {
        selected: {
            answer: {
                id: null,
                answer_id: null,
                text: null,
            },
        },
    };

    private appDebug!: string;

    private pageOfItems: any = [];

    private pager: any = {
        currentPage: 1,
    };

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.laravelErrorsService = new LaravelErrorsService();
        this.googleMapService = new GoogleMapService();
        this.googleMapWindowService = new GoogleMapWindowService(this);
        this.mediaService = new MediaService();
        this.locationService = new LocationService(this);
        this.modalService = new ModalService(this);
        this.assetService = new AssetService();
        this.answerService = new AnswerService(this);
        this.pusherService = new PusherService(this);
        this.userGameService = new UserGameService(this);
        this.gameCityRequest = new GameCityRequest(this);
        this.gameCityResource = new GameCityResource(this);
        this.userGameRequest = new UserGameRequest(this);
        this.userGameResource = new UserGamesResource(this);
        this.gameTypeValidationRequest = new GameTypeValidationRequest(this);
        this.gameTypeValidationResource = new GameTypeValidationResource(this);
        this.userGameExpiredRequest = new UserGameExpiredRequest(this);
        this.userGameExpiredResource = new UserGameExpiredResource(this);
        this.userAnswerRequest = new UserAnswerRequest(this);
        this.userAnswerResource = new UserAnswerResource(this);
        this.userAnswerService = new UserAnswerService(this);
        this.gameTypeService = new GameTypeService(this);
        this.shareService = new ShareService(this);
        this.appDebug = process.env.APP_DEBUG;
    }

    get user(): any {
        return this.$store.getters.user;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    public async mounted(): Promise<void> {
        await this.pusherService.team(() => this.collectAll());

        this.modalService.observe(() => {
            this.onMap.selected.answer = {
                id: null,
                answer_id: null,
                text: null,
            };
        });

        document.addEventListener('iziToast-opening', (data: any) => {
            // if (data.detail.class === `game-start-${this.currentLocation.id}`) {
            // this.updateLocationGameStartNotification();
            // }
        });
    }

    public async created(): Promise<void> {
        await this.$store.dispatch('storeMapGame', {
            'id': parseInt(this.$route.params.gameId),
            'user_game': {
                'id': parseInt(this.$route.params.userGameId),
            },
        });
        await this.collectAll();
        this.locationService.watchUserLocation();
        await this.$store.dispatch('storeComponent', 'TeamUserGame');
    }

    public async collectAll(): Promise<void> {
        await axios.all([
            this.gameCityRequest.show(this.map.game.id, null),
            this.userGameRequest.show(null, `team/${this.$route.params.key}/start`),
            this.userGameRequest.show(null, `team/${this.$route.params.key}`),
            this.userGameRequest.show(null, `team/expired?user_game_id=${this.map.game.user_game.id}&team_key=${this.$route.params.key}`),
            this.userAnswerRequest.show(null, `team/${this.$route.params.key}`),
            this.gameTypeValidationRequest.index(null, `validate?user_game_id=${this.map.game.user_game.id}&component=TeamUserGame`),
        ]).then(
            axios.spread((...responses: any) => {
                this.gameCityResource.show(responses[0].data);
                this.userGameResource.show(responses[1].data, true);
                this.userGameResource.show(responses[2].data, false);
                this.userGameExpiredResource.index(responses[3].data, false);
                this.userAnswerResource.show(responses[4].data, false);
                this.gameTypeValidationResource.index(responses[5].data);
            }),
        ).catch((errors: any) => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    @Watch('onMap.selected.answer', { deep: true })
    userOnMapSelectedAnswer(answer: any) {
        if (answer) {
            this.answerService.store(answer);
        }
    }

    @Watch('map.start.quiz', { deep: true })
    onMapStart(val: any) {
        if (typeof val === 'object') {
            //@todo notification for user of start game
            // console.log(val);
        }
    }

    private onChangePage(pageOfItems: any) {
        this.pageOfItems = pageOfItems;
    }

    private async storeUserAnswer(locationId: any = null) {
        await this.userAnswerService.store(() => {
            this.$store.dispatch('storeIsThankYou', true);
            setTimeout(async () => {
                await this.collectAll();
                this.modalService.hide(locationId !== null
                    ? locationId
                    : this.map.user.onMap.location.id,
                );
                await this.$store.dispatch('storeIsThankYou', false);
            }, 4000);
        });
    }

    private async updateUserGameToForceEndGame() {
        await this.userGameService.update(() => {
            this.collectAll();
        });
    }
}
</script>
<style lang='scss' scoped src='./TeamUserGame.scss'></style>
