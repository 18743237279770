<template src='./cart.html'></template>
<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import axios from 'axios';
import LaravelErrorsService from '@/services/LaravelErrorsService';
import AxiosService from '@/services/Axios/AxiosService';

@Component({
    components: {
        StripeCheckout,
    },
})
export default class Cart extends Vue {
    private authService: AuthService;

    private axiosService: AxiosService;

    private laravelErrorsService: LaravelErrorsService;

    private stripe: Object | any = {
        publishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
    };

    constructor(options: any) {
        super(options);
        this.authService = new AuthService(this.$router, this.$store);
        this.axiosService = new AxiosService(this.user);
        this.laravelErrorsService = new LaravelErrorsService();
    }

    get currentCart(): any {
        return this.$store.getters.currentCart;
    }

    get user() {
        return this.$store.getters.user;
    }

    public created() {
        this.$store.dispatch('storeUser');
        this.$store.dispatch('storeCart');
    }

    public mounted() {
        this.discountFreeGameRedirect();
    }

    public metaInfo(): Object | any {
        return {
            meta: [
                {
                    name: 'description', content: this.$t('meta.description'),
                },
                {
                    name: 'keywords', content: this.$t('meta.keywords'),
                },
            ],
            title: this.$t('meta.title'),
        };
    }

    private checkout() {
        (this.$refs.checkoutRef as StripeCheckout).redirectToCheckout();
    }

    private destroyOrder() {
        axios.delete(`${process.env.API_URL}/orders/${this.currentCart.order.id}`,
            this.axiosService.config(),
        ).then(response => {
            this.laravelErrorsService.destroyErrors();
            if (response.data.success) {
                this.$store.dispatch('destroyCart', {});
                location.reload();
            }
        }).catch(errors => {
            if (typeof errors.response !== 'undefined' && errors.response.status === 401) {
                this.authService.forceLogout(String(this.$t('alert.error.notification.device_limit')));
            }

            this.$store.dispatch('destroyCart', {});
            location.reload();
            if (typeof errors.response !== 'undefined' && errors.response.data.errors) {
                this.laravelErrorsService.storeErrors(errors.response.data.errors);
                this.laravelErrorsService.showErrorNotifications();
            }
        });
    }

    private getTotal(): string {
        let total: number = this.currentCart.order.total / 100;

        return parseFloat(String(total)).toFixed(2);
    }

    private discountFreeGameRedirect(): void {
        if (this.currentCart.order.status === 'success'
            && this.currentCart.order.total === 0) {
            this.$router.push({
                name: 'TeamCreate',
                params: {
                    gameId: this.currentCart.game_id,
                    userGameId: this.currentCart.order.user_game_id,
                },
            });
        }
    }
}
</script>
<style lang='scss' scoped src='./cart.scss'></style>
