export default class QuickResponseCodeService {
    public getRouteName(type: string | null): string {
        let response = 'dummy';

        if (type === 'cerberus') {
            response = 'QuickResponseCodeCerberus';
        }

        if (type === 'login') {
            response = 'QuickResponseCodeLogin';
        }

        if (type === 'register') {
            response = 'QuickResponseCodeRegister';
        }

        if (type === 'forgot-user-name') {
            response = 'QuickResponseCodeForgotUserName';
        }

        return response;
    }
}
