import { Vue } from 'vue-property-decorator';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue/src';
import App from './App.vue';
import './registerServiceWorker';
import { router } from '@/plugins/vue-router';
import '@/plugins/vue-router-middleware';
import '@/plugins/vuex-router-sync';
import store from './store';
import i18n from './i18';
import 'popper.js';
import 'izitoast/dist/js/iziToast.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'font-awesome-sass/assets/stylesheets/_font-awesome-sprockets.scss';
import 'font-awesome-sass/assets/stylesheets/_font-awesome.scss';
import 'izitoast/dist/css/iziToast.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GmapVue, { components } from 'gmap-vue';
import GmapCustomMarker from 'vue2-gmap-custom-marker/gmap-custom-marker.vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LazyTube from 'vue-lazytube';
import VueMeta from 'vue-meta';
import CountryFlag from 'vue-country-flag';
import Busy from '@/components/Busy/Busy.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import UserService from '@/services/UserService';
import VuePusher from './plugins/VuePusher';

const { Cluster } = components;

Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(GmapVue, {
    load: {
        key: process.env.GOOGLE_API_KEY,
        libraries: 'places,drawing,visualization',
    },
    autoBindAllEvents: false,
    installComponents: true,
    dynamicLoad: false,
});
Vue.use(LazyTube);

Vue.use(VuePusher, {
    apiKey: process.env.PUSHER_APP_KEY,
    channelAuthorization: {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${UserService?.getUser()?.accessToken}`,
        },
        endpoint: process.env.ECHO_AUTH_ENDPOINT,
        transport: 'ajax',
    },
    cluster: process.env.PUSHER_APP_CLUSTER,
    forceTLS: true,
    enableStats: true,
});

Vue.component('gmap-cluster', Cluster);
Vue.component('gmap-custom-marker', GmapCustomMarker);
Vue.component('country-flag', CountryFlag);
Vue.component('busy', Busy);
Vue.component('pagination', Pagination);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
