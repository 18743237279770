import { Store } from 'vuex';
import VueRouter from 'vue-router';

export default class GameTypeService {
    public $store: Store<unknown>;

    public $router: VueRouter;

    public static Individual: number = 1;

    public static Team: number = 2;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
    }

    get map(): any {
        return this.$store.getters.map;
    }
}
