import { Store } from 'vuex';
import VueRouter from 'vue-router';
import GameTypeService from '@/services/GameTypeService';

export default class ValidateResponseService {
    public $store: Store<unknown>;

    public $router: VueRouter;

    constructor(parent: any) {
        this.$store = parent.$store;
        this.$router = parent.$router;
    }

    get map(): any {
        return this.$store.getters.map;
    }

    get isBusy(): boolean {
        return this.$store.getters.isBusy;
    }

    get isThankYou(): boolean {
        return this.$store.getters.isThankYou;
    }

    get isAnswering(): boolean {
        return this.$store.getters.isAnswering;
    }

    get game(): any {
        return this.$store.getters.game;
    }

    get endGame(): any {
        return this.$store.getters.endGame;
    }

    public isEmptyResponse(response: any): void {
        if (response.length === 0) {
            this.$router.push({
                name: 'UserGameInvalid',
                params: {
                    gameId: this.game.id,
                    userGameId: this.game.user_game.id,
                },
            });
        }
    }

    public isEmptyOrder(response: any): void {
        if (response === null) {
            this.$router.push({
                name: 'UserGameUnPaid',
                params: {
                    gameId: this.game.user_game.id,
                },
            });
        }
    }

    public isPromoEligible(): boolean {
        return this.game.is_promo === true && this.game.user_game.promo !== null && // locations_stats
            this.endGame.statistics[0]['Mana atbilde'] === this.endGame.statistics[0]['Pareizā atbilde'] && // quizzes_stats
            this.endGame.statistics[1]['Mana atbilde'] === this.endGame.statistics[1]['Pareizā atbilde']; // quizzes_stats
    }

    public isEmptyTeam(response: any): void {
        if (response === null) {
            this.$router.push({
                name: 'TeamCreate',
                params: {
                    gameId: this.game.id,
                    userGameId: this.game.user_game.id,
                },
            });
        }
    }

    public isNotUserGameExpired(): void {
        if (this.map.isUserGameExpired === false) {
            this.redirectGameType();
        }
    }

    public isNotUserGameAnswersComplete(): void {
        if (this.map.isUserGameAnswersComplete === false) {
            this.redirectGameType();
        }
    }

    public redirectGameType(): void {
        if (this.map.game.user_game.game_type_id === GameTypeService.Individual) {
            window.location.replace(`${process.env.APP_URL}/user/game/${this.map.game.id}/${this.map.game.user_game.id}`);
        }

        if (this.map.game.user_game.game_type_id === GameTypeService.Team) {
            window.location.replace(`${process.env.APP_URL}/user/game/team/${this.map.game.id}/${this.map.game.user_game.id}/${this.map.game.user_game.team.key}`);
        }
    }

    public redirectEndgameType(): void {
        if (this.map.game.user_game.game_type_id === GameTypeService.Individual) {
            window.location.replace(`${process.env.APP_URL}/endgame/${this.map.game.id}/${this.map.game.user_game.id}`);
        }

        if (this.map.game.user_game.game_type_id === GameTypeService.Team) {
            window.location.replace(`${process.env.APP_URL}/endgame/team/${this.map.game.id}/${this.map.game.user_game.id}/${this.map.game.user_game.team.key}`);
        }
    }
}
